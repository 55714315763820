import styled from 'styled-components';

const BoldFont = 'Rubik-Medium';

const Header = styled.div`
  width: 100%;
  float: right;
  padding: 15px 0px 15px 250px;
  background-color: ${({bgColor}) => bgColor ? bgColor : '1C4667'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 951px) {
    width: 100%;
    padding-left: 20px;
  }
  @media (max-width: 650px) {
    display: block;
    padding-left: 10px;
  }
`;

const SetSmartGoalsButton = styled.div`
  font-family: ${BoldFont};
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  width: 100%;
  text-align: center;
  padding: 20px;
  margin: ${({margin}) => margin ? margin : '0 0 30px'};
  background: linear-gradient(180deg, #52A8ED 0%, #61C8FB 100%);
  border-radius: 4px;
  cursor: pointer;
  > img {
    width: ${({width}) => width ? width : '16px'};
    vertical-align: middle;
    float: left
  }
`;

const PointsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 32%;
  padding-right: 10px;
  text-align: center;
  @media (max-width: 767px) {
    width: auto;
  }
  @media (max-width: 590px) {
    height: auto;
    width: 100%;
    padding: 10px 15px 10px 10px;
    justify-content: space-between;
  }
`;

const MonthlyPoints = styled.div`
  font-size: 12px;
  color: #FFFFFF;
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  .calendarStyle {
    width: 25%;
  }
`;

const Points = styled.div`
  display: inline-block;
  float: right;
  padding-left: 10px;
  text-align: left;
  font-family: Rubik-Medium;
  > div:first-child {
    text-transform: uppercase;
    > span {
      text-transform: capitalize;
      font-family: Rubik-Light;
    }
  }
  > div:nth-child(2) {
    font-weight: 700;
    font-family: Rubik-Medium;
  }
`;

const TotalPoints = styled.div`
  font-family: Rubik-Light;
  font-size: 12px;
  color: #FFFFFF;
  height: 100%;
  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  > div:nth-child(2) {
    font-weight: 700;
    font-family: Rubik-Medium;
  }
`;

const ThemeBlockContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 48px;
  > p {
    color: #fff;
    font-family: ${BoldFont};
    border-bottom: 1.5px solid white;
    display: inline-block;
    font-size: 15px;
    margin: 0 0 2px 9px;
    @media (max-width:400px) {
      padding-top: 5px;
    }
  }
  > img {
    display: inline-block;
    width: 30px;
    height: auto;
    @media (max-width:400px) {
      margin: 0 auto;
    }
  }
  @media (max-width: 650px) {
    text-align: center;
    display: block;
    padding: 0;
    margin: 0 auto;
  }
  @media (max-width:400px) {
    display: block;
    width: 100%;
    padding: 0 10px;
  }
`;

export { Header, SetSmartGoalsButton, MonthlyPoints, TotalPoints, PointsContainer, Points, ThemeBlockContainer};
