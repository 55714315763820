/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { Header, ThemeBlockContainer, PointsContainer, MonthlyPoints, TotalPoints, Points} from './style';
import { CalendarIcon } from '../SvgComponentsV2';
import { Waiting } from '../../components';
import {commaSeperator, getActivityDate} from '../../utils/methods';
import {getUseronthlyDetailsAPI} from '../../redux/actions';
import { ImageUrl } from '../../utils/constants';
import {withTranslation} from 'react-i18next';

class PortalHeader extends Component {

  componentDidMount() {
    let currentMonth =  moment().startOf("month").format('MMMM');
    let currentYear = moment().startOf('year').format('YYYY');
    let obj = getActivityDate(currentMonth.slice(0,3), currentYear);
    let startDate = moment(obj.startDate, 'MM-DD-YYYY').format('YYYY-MM-DD');
    const { fetchUseronthlyDetails } = this.props;
    fetchUseronthlyDetails(startDate);
  }

  render() {
    const { userMonthlyDetails, reward, themesHeader} = this.props;
    const d = new Date;
    const currentMonthIndex = d.getMonth();
    if(isNull(userMonthlyDetails)) {
      return <Waiting />
    }
    const themeStyle = !isNull(themesHeader) && !isUndefined(themesHeader) && themesHeader.length > 0 && themesHeader.filter((item, index) => index >= currentMonthIndex);
    return (
      <Header bgColor={themeStyle && themeStyle[0]['background_color'] || 0} >
        <ThemeBlockContainer>
          {themeStyle.length > 0 && <img src={`${ImageUrl}/${themeStyle && themeStyle[0].image_url || ''}`} />}
          <p>{themeStyle && themeStyle[0].theme_text || ''}</p>
        </ThemeBlockContainer>
        <PointsContainer>
          <MonthlyPoints>
            <div className="calendarStyle">
              <CalendarIcon width="40px" height="40px"/>
            </div>
            <Points>
              <div>{themeStyle && themeStyle[0].month || ''}<span><br/> {this.props.t("Points Earned")}</span></div>
              <div>{commaSeperator(parseInt(userMonthlyDetails['reward_points']))}{this.props.t("pts")}</div>
            </Points>
          </MonthlyPoints>
          <TotalPoints>
            <div>{this.props.t("Total Points")}</div>
            <div>{commaSeperator(parseInt(reward ? reward.length > 0 ? reward[0].total : 0 : 0))}{this.props.t("pts")}</div>
          </TotalPoints>
        </PointsContainer>
        {/*<SetSmartGoalsButton onClick={() => this.ShowLogWorkout()} logWorkoutButton>
          <img src={'/images/logWorkout.svg'}/>
          Log Workout
          <i className="fas fa-angle-right"/>
        </SetSmartGoalsButton>
        {
          showModal && <LogWorkoutCategory title="Add Your Monthly Workout" subContent="Dashboard / monthly workout"  showModal={showModal} onHide={this.ShowLogWorkout} history={history}/>
        }*/}
      </Header>
    )
  }
}

PortalHeader.propTypes = {
  history: PropTypes.object.isRequired,
  fetchUseronthlyDetails: PropTypes.func,
  userMonthlyDetails: PropTypes.object,
  isAdmin: PropTypes.bool,
  role: PropTypes.bool,
  reward: PropTypes.array,
  themesHeader: PropTypes.array,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  userMonthlyDetails: state.profileData.userMonthlyDetails,
  reward: state.profileData.response.reward,
  themesHeader: state.profileData.themesHeader
});

const mapDispatchToProps = (dispatch) => ({
  fetchUseronthlyDetails: (date) => dispatch(getUseronthlyDetailsAPI(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)( withTranslation()(PortalHeader));
