import React , {Component} from 'react';
import {TopPanel,Button,TopPanelContent,MiddlePanel,MiddlePanelSecond,MiddleFooterSection,FooterSection,IntegrationContentContainer} from './styles'
import PropTypes from "prop-types";
import Slider from "react-slick-16";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const dotNames = ["Workplace","Engagement","Culture"];
const dotNames1 = ["Engagement","Culture","Workplace"];

class PlatformEngagementPage extends Component{
  TopPanelSection = (history) => (
    <TopPanel>
      <div style={{paddingTop:"100px"}}>
        <p>IT’S ALL ABOUT THEM</p>
        <h1>ENGAGEMENT <br/> INVIGORATED</h1>
        <img src="/public/images/purple_reactangle.png"/>
        <TopPanelContent>
          <p>
            Don’t just hope that a culture shift is happening...<br/>
            see the results for yourself in real-time.
          </p>
        </TopPanelContent>
        <Button onClick={()=> history.push('/contact')}>BOOK A DEMO</Button>
      </div>
      <div>
        <picture>
          <img src="/public/images/engagement.png"/>
        </picture>
      </div>
    </TopPanel>
  );
  MiddlePanelSection = () => (
    <MiddlePanel>
      <div>
        <img src="/public/images/PlatformEngagement/middle1.png"/>
      </div>
      <div>
        <h2>Manage your own wellness<br /> mainframe through the<br /> &quot;admin dashboard&quot;</h2>
        <TopPanelContent>
          <p>
            Your entire company’s participation,<br /> performance, and progress at your<br /> fingertips.
            View and download reports<br /> with up-to-the-minute stats.
          </p>
        </TopPanelContent>
      </div>
    </MiddlePanel>
  );
  MiddlePanelSectionSecond = () => (
    <MiddlePanelSecond>
      <div>
        <h2>Ask the right questions
          <br />to better gauge
          <br />your employees
        </h2>
        <TopPanelContent>
          <p>
            Uncover crucial relationships between wellness and engagement through powerful survey tools.
          </p>
        </TopPanelContent>
      </div>
      <div>
        <img src="/public/images/PlatformEngagement/middle2a.png"/>
      </div>
    </MiddlePanelSecond>
  );
  middleFooterSection = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      draggable: true,
      arrows: false,
      centerPadding: "25%",
      dotsClass: "slick-dots custom-dots",
      centerMode: true,
      initialSlide: 1,
      customPaging: (index) => (
        <ul>
          <li><button /></li>
          <h4>{dotNames[index]}</h4>
        </ul>
      ),
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            dots: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: true,
            arrows: false,
            dotsClass: "slick-dots custom-dots",
            centerMode: false,
            customPaging: (index) => (
              <ul>
                <li><button /></li>
                <h4>{dotNames1[index]}</h4>
              </ul>
            ),
          }
        },
      ]
    };
    return <MiddleFooterSection>
      <div>
        <p>Understand what your population needs with easy-to-read charts and graphs.</p>
      </div>
      <IntegrationContentContainer>
        <Slider {...settings}>
          <div className="carousel-item">
            <img src="/public/images/PlatformEngagement/footer_1b.png"/>
          </div>
          <div className="carousel-item">
            <img src="/public/images/PlatformEngagement/footer_1c.png"/>
          </div>
          <div className="carousel-item">
            <img src="/public/images/PlatformEngagement/engagement_workplace.png"/>
          </div>
        </Slider>
        <div>
          <p>Engagement <br />Gain valuable insights on habits and points of view so you can drive behavior change.
            Celebrate your wins and monitor success over the course of years.</p>
        </div>
      </IntegrationContentContainer>
    </MiddleFooterSection>
  };
  footerSection = (history) => (
    <FooterSection>
      <div>
        <p>Are you ready to increase engagement?</p>
        <Button onClick={()=> history.push('/contact')} display={'block'}>Get Started</Button>
      </div>
    </FooterSection>
  );
  render() {
    const {history} = this.props;
    return(
      <div>
        {this.TopPanelSection(history)}
        {this.MiddlePanelSection()}
        {this.MiddlePanelSectionSecond()}
        {this.middleFooterSection()}
        {this.footerSection(history)}
      </div>
    );
  }
}
PlatformEngagementPage.propTypes = {
  history: PropTypes.object,
};
export default PlatformEngagementPage;